import React, { useState } from "react";
import { decodeHTMLEntities } from "utils/utilsDati";
import { Grid, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import coloriVetrina from "json/coloriVetrina.json";
import ModalDettagliAnnuncio from "./ModalDettagliAnnuncio";
import JobErCard from "components/JobErCard";

function ItemListaAnnunciMore(props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Grid item xs={12} ml={4} mr={4} mb={2}>
        <JobErCard
          sx={{ margin: "20px !important" }}
          child={
            <ListItem
              alignItems="flex-start"
              key={props.annuncio.uuid}
              onClick={() => {
                setOpen(true);
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <ListItemAvatar key={props.annuncio.uuid + "_candidatura"}>
                    <img
                      src={props.annuncio?.gruppo ? require("immagini/ambiti_lavoro/gr" + props.annuncio.gruppo + "-260x145.jpg") : null}
                      sx={{
                        borderRadius: 15,
                      }}
                      alt=""
                    />
                  </ListItemAvatar>
                </Grid>
                <Grid item xs={12} md={9}>
                  <ListItemText
                    key={props.annuncio.uuid + "_text"}
                    primary={""}
                    primaryTypographyProps={{
                      fontWeight: "bold",
                      color: coloriVetrina.blue,
                    }}
                    secondary={
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            component="p"
                            sx={{
                              fontSize: "16px!important",
                              color: coloriVetrina.blue,
                            }}
                          >
                            {props.annuncio?.titoloAnnuncio && <strong>{props.annuncio.titoloAnnuncio.toUpperCase()} </strong>}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            component="p"
                            sx={{
                              fontSize: "16px!important",
                              color: coloriVetrina.blue,
                            }}
                          >
                            {props.annuncio?.comune && <strong> Comune di: {props.annuncio.comune} </strong>}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            component="p"
                            sx={{
                              fontSize: "16px!important",
                              color: coloriVetrina.blue,
                            }}
                          >
                            {props.annuncio?.descrizioneLavoro?.length > 250
                              ? decodeHTMLEntities(props.annuncio.descrizioneLavoro.substring(0, 250)) + " ..."
                              : decodeHTMLEntities(props.annuncio.descrizioneLavoro)}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          }
        />
      </Grid>

      <ModalDettagliAnnuncio open={open} setOpen={setOpen} titolo={props.annuncio.titoloAnnuncio} descrizioneLavoro={props.annuncio.descrizioneLavoro} />
    </>
  );
}

export default ItemListaAnnunciMore;
